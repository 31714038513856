/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Link} from "gatsby";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Vous êtes nombreux à me demander conseil pour trouver un pays pas cher pour partir en vacances en Europe. Se poser la question est un bon point de départ pour ceux qui veulent apprendre à voyager en Europe, dans le cadre de la préparation d’un tour d’Europe ou le temps d’un week-end entre amis ou en couple."), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Optimisez votre budget pour un voyage en Europe"), React.createElement(_components.p, null, "Plusieurs classements s’intéressent aux pays les moins chers en Europe, au travers notamment du coût de la vie dans les grandes capitales européennes et les villes les plus touristiques."), React.createElement(_components.p, null, "Durant l’été et la haute saison, certaines destinations deviennent rapidement hors de prix. Le coût des billets d’avion, des chambres d’hôtel voire les tarifs des restaurants et des musées augmentent énormément."), React.createElement(_components.p, null, "Mais ne vous inquiétez pas, il existe des moyens de voyager en Europe sans casser votre tirelire. Grâce à notre ", React.createElement(Link, {
    to: "/"
  }, "outil de planification de voyage"), ", vous pouvez trouver les meilleures options adaptées à votre budget et à vos préférences."), React.createElement(_components.h3, null, "L’hébergement"), React.createElement(_components.p, null, "Éliminons d’abord la question de l’hébergement, souvent l’une des dépenses principales en amont du voyage. Or, pour chaque destination, on a souvent l’embarras du choix et une large gamme de prix entre les hôtels, les chambres d’hôtes, les appartements et les auberges de jeunesse. Pour gagner du temps, vous pouvez utiliser notre ", React.createElement(Link, {
    to: "/"
  }, "sélection des meilleurs hébergements en Europe"), "."), React.createElement(_components.h3, null, "Les transports"), React.createElement(_components.p, null, "L’autre gros poste de dépense est le transport, notamment si vous voulez prendre l’avion. Utilisez notre ", React.createElement(Link, {
    to: "/"
  }, "outil de planification"), " pour comparer les prix des billets, y compris ceux des compagnies low cost, afin de trouver l’option la plus économique."), React.createElement(_components.p, null, "Pour faire baisser la facture globale de votre voyage, n’oubliez pas de comparer aussi les prix des modes de transport alternatifs à l’avion. Si le sujet vous intéresse, notre ", React.createElement(Link, {
    to: "/"
  }, "outil de planification"), " permet de découvrir l’Europe en bus ou en train. Ces moyens de transport sont souvent moins chers et tout aussi pratiques."), React.createElement(_components.h3, null, "Le coût de la vie"), React.createElement(_components.p, null, "Maintenant que les dépenses d’hébergement et de transport sont réglées, nous pouvons regarder le coût de la vie. Bien qu’il semble difficile d’éviter la surfréquentation touristique estivale, il est tout à fait possible pour des étudiants ou de jeunes couples de partir à la découverte de l’Europe avec un budget serré."), React.createElement(_components.p, null, "Je vous propose une sélection des pays les moins chers en Europe, à partir de ce que j’ai constaté sur place, mais aussi des classements régulièrement réalisés par Tripadvisor et Priceoftravel.")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "1) Sofia, Bulgarie"), React.createElement(_components.p, null, "« Toujours grandissante, jamais vieillissante », la capitale bulgare est au carrefour d’influences culturelles multiples. Au-delà de la cathédrale Alexandre Nevski, de l’église Sainte-Sophie et des sources d’eau chaude en libre accès, c’est surtout le quartier étudiant qui marque."), React.createElement(_components.p, null, "Des voyageurs reviennent mitigés en raison du manque de sites touristiques à visiter. Il faut aussi noter la rareté et le coût des vols pour se rendre en Bulgarie, même avec des compagnies low cost depuis Paris-Beauvais (Wizz Air). Néanmoins, Sofia paraît être toute indiquée si vous souhaitez voyager pas cher tout en profitant de la vie sur place, avec de nombreux bars, restaurants et clubs."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-sofia"
  }, React.createElement("span", null, "Explorer la carte touristique de Sofia")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "2) Bucarest, Roumanie"), React.createElement(_components.p, null, "L’ancien Petit Paris est marqué par les héritages architecturaux du régime de Ceausescu, symbolisé par le palais du Parlement. De l’aveu général, ce n’est pas la plus belle ville de Roumanie et de fait Bucarest peine à attirer les touristes. Il faut dire aussi que les infrastructures hôtelières font en partie défaut."), React.createElement(_components.p, null, "Pour ceux qui font l’effort d’aller jusqu’à la capitale roumaine, des prix attractifs vous attendent."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-bucarest"
  }, React.createElement("span", null, "Explorer la carte touristique de Bucarest")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "3) Cracovie, Pologne"), React.createElement(_components.p, null, "Cracovie a tout pour vous arracher le cœur. Héritage médiéval, passé royal, déchirures du XXe siècle européen et omniprésence du souvenir du pape Jean-Paul II… L’atmosphère d’une ville de province, sans certains aspects négatifs de la muséification à l’œuvre en Europe, phénomène évité par la présence massive d’étudiants."), React.createElement(_components.p, null, "Sans doute parmi les plus belles expériences de mon tour d’Europe, grâce au chaleureux accueil de la jeunesse polonaise et aux fantastiques découvertes gastronomiques que j’ai pu y faire. J’en suis encore ému aujourd’hui. Mon numéro 1 personnellement !"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-cracovie"
  }, React.createElement("span", null, "Explorer la carte touristique de Cracovie")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "4) Budapest, Hongrie"), React.createElement(_components.p, null, "Tout pousse à aimer la capitale hongroise, curieux mélange entre postsoviétisme et capitalisme sauvage. Une grandeur passée aussi qui ne tient pas seulement à la présence majestueuse du Danube, de la grande synagogue ou du Parlement."), React.createElement(_components.p, null, "Une vie tranquille entre bains, salons de thé et culture gastronomique d’une grande diversité. Budapest n’attend que vous !"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-budapest"
  }, React.createElement("span", null, "Explorer la carte touristique de Budapest")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "5) Riga, Lettonie"), React.createElement(_components.p, null, "Riga est la plus grande des trois capitales baltes. La plus cosmopolite aussi, grâce à son passé de port de La Hanse. Entre la vieille ville qui date du XIIIe siècle et les sublimes constructions Art nouveau, la capitale lettone est très riche."), React.createElement(_components.p, null, "Depuis l’indépendance, plusieurs bâtiments de la période pré-soviétique ont été entièrement reconstruits, participant à la mise en récit de l’histoire de la nation lettone. Un autre coup de cœur personnel !"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-riga"
  }, React.createElement("span", null, "Explorer la carte touristique de Riga")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "6) Zagreb, Croatie"), React.createElement(_components.p, null, "Zagreb est une bonne surprise. La capitale du plus récent pays membre de l’Union est entièrement aux couleurs du drapeau européen, toute à son enthousiasme de l’entrée dans l’UE."), React.createElement(_components.p, null, "Si les attractions touristiques sont limitées, on apprécie les nombreux marchés et la visite des quartiers hérités du communisme. La nourriture est bonne, les bars et clubs remplis."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-zagreb"
  }, React.createElement("span", null, "Explorer la carte touristique de Zagreb")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "7) Varsovie, Pologne"), React.createElement(_components.p, null, "La ville est tellement chargée d’histoire qu’il m’est difficile d’en parler sans penser à ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Bronis%C5%82aw_Geremek",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Bronislaw Geremek"), ". Né juif, converti enfant au catholicisme après s’être échappé du ghetto de Varsovie, historien dissident sous le régime communiste, devenu homme politique et fervent européen… Se promener dans la capitale polonaise, c’est d’abord traverser le XXe siècle européen."), React.createElement(_components.p, null, "Outre l’héritage aristocratique du parc Lazienki, Varsovie offre aussi un centre-ville où les gratte-ciels se multiplient et, légèrement excentré au nord-ouest, le plus grand centre commercial d’Europe de l’Est."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-varsovie"
  }, React.createElement("span", null, "Explorer la carte touristique de Varsovie")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "8) Vilnius, Lituanie"), React.createElement(_components.p, null, "Vilnius, la capitale de la Lituanie, offre une atmosphère unique où se mêlent modernité et histoire. Avec son centre historique classé au patrimoine mondial de l'UNESCO, la ville regorge d’églises baroques, de rues pavées et de charmantes places."), React.createElement(_components.p, null, "Le coût de la vie y est relativement bas, de quoi donner envie de découvrir les autres pays baltes."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-vilnius"
  }, React.createElement("span", null, "Explorer la carte touristique de Vilnius")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "9) Bratislava, Slovaquie"), React.createElement(_components.p, null, "J’ai joué de malchance avec la capitale slovaque, découverte sous des trombes d’eau. Bratislava est devenue une destination courue le temps d’un week-end."), React.createElement(_components.p, null, "Cela tient sans doute à la douceur tranquille de cette ville à 60 km de Vienne. J’ai apprécié de côtoyer une jeunesse cosmopolite, tournée vers l’international et toujours prête à renseigner."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-bratislava"
  }, React.createElement("span", null, "Explorer la carte touristique de Bratislava")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "10) Istanbul, Turquie"), React.createElement(_components.p, null, "A cheval sur l’Europe et l’Asie, Istanbul est une destination dépaysante pour les voyageurs cherchant à explorer une ville riche en histoire, tout en maîtrisant leur budget. Ses monuments emblématiques, tels que la basilique Sainte-Sophie, la Mosquée Bleue et le Grand Bazar, font partie des incontournables."), React.createElement(_components.p, null, "Bien que la ville soit touristique, le coût de la vie reste abordable, notamment en matière de nourriture et de logement, ce qui en fait un très bon choix. Surveillez les promos sur les billets d'avion !"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Link, {
    to: "/carte-touristique-istanbul"
  }, React.createElement("span", null, "Explorer la carte touristique d'Istanbul")), "\n"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Méthodologie"), React.createElement(_components.p, null, "Pour arriver à un classement présentant les pays les moins chers en Europe, Tripadvisor compare le budget qu’il faut pour un couple dormant une nuit dans un hôtel quatre étoiles, consommant deux cocktails, un dîner avec deux plats et une bouteille de vin ainsi qu’un aller-retour de 6 km en taxi."), React.createElement(_components.p, null, "Priceoftravel édite deux indices, l’un pour les backpackers, l’autre pour ceux qui vont dans des hôtels trois étoiles. Le budget backpacker prend en compte une nuit dans le dortoir le moins cher de la ville, deux voyages en transports publics par jour, trois repas économiques, le ticket d’entrée pour une attraction majeure de la ville et trois consommations d’alcool. Le budget trois étoiles inclut une nuit dans un hôtel central, un aller-retour en taxi de 6 km, le ticket d’entrée pour une attraction majeure, trois repas économiques, trois consommations d’alcool. J’ai essayé de synthétiser les deux.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
