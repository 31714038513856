import GATSBY_COMPILED_MDX from "/opt/build/repo/gatsby/src/posts/blogPosts/paysmoinschers.mdx";
import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ShareButton from "../components/shareButton";
import {MDXProvider} from "@mdx-js/react";
export function Head({data}) {
  const {mdx} = data;
  const {frontmatter} = mdx;
  const {title, description, path} = frontmatter;
  return React.createElement(Seo, {
    title: title,
    description: description,
    slug: path,
    image: `/thumbs/posts${path}.png`
  });
}
function Template({data, children}) {
  const {mdx} = data;
  const {frontmatter} = mdx;
  const {title, path} = frontmatter;
  const url = `${process.env.GATSBY_BASEURL}${path}`;
  return React.createElement(Layout, {
    content: "page"
  }, React.createElement("article", null, React.createElement("div", {
    className: "blog-post-container"
  }, React.createElement("div", {
    className: "blog-post"
  }, React.createElement("header", null, React.createElement("div", null, React.createElement("h1", null, title))), React.createElement("aside", null, React.createElement(ShareButton, {
    title: title,
    url: url
  })), React.createElement("main", null, React.createElement(MDXProvider, null, children))))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        title
        description
      }
    }
  }
`;
